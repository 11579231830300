@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?vuprhb');
  src:  url('fonts/icomoon.eot?vuprhb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vuprhb') format('truetype'),
    url('fonts/icomoon.woff?vuprhb') format('woff'),
    url('fonts/icomoon.svg?vuprhb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="kl-"], [class*=" kl-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kl-paso-send-regular:before {
  content: "\e9901";
}
.kl-paso-link-regular:before {
  content: "\e9902";
}
.kl-paso-document-list:before {
  content: "\e9903";
}
